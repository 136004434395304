<template>
  <div class="c-user-cards">
      <div class="account" v-for="(item,index) in list " :key="index" @click="onPageToHomepage(cardType == 'follow' ? item.follow_user_id : item.user_id)">
            <div class="left">
                <div class="avatar">
                    <div class="me-cover"></div>
                    <van-image :src="item.head_img || ''" lazy-load width="100%" fit="cover" loading-icon="" error-icon=""></van-image>
                </div>
                <c-image iUrl="icon-vip_s" iClass="vip" iWidth="18" iHeight="18" v-if="item.vip_flag"></c-image>
            </div>
            <div class="center">
                <h4>{{item.nickname}}</h4>
                <div class="tags_s">
                    <c-image :iUrl="item.level ? 'icon-vip_'+item.level : ''" iClass="tag_s" iWidth="28" iHeight="24"></c-image>
                    <c-image iUrl="icon-original" iClass="tag_s" iWidth="28" iHeight="24" v-if="item.bonus_level > 100"></c-image>
                </div>
                <div class="info">
                    <p>作品<i>{{item.work_count}}</i></p><em>|</em><p>经验值<i>{{item.experience}}</i></p>
                </div>
            </div>
            <div class="right">
                <c-image iUrl="icon-more" iClass="more" iWidth="6" iHeight="30"></c-image>
            </div>
        </div>
  </div>
</template>

<script>
import { Image } from 'vant';
import CImage from "./CImage.vue";
import { mapState } from "vuex"
export default {
    name: "c-user-cards",
    components: {
        [Image.name]: Image,
        [CImage.name]: CImage
    },
    props: {
        list: {
            type: Array,
            default: (() => []),
            required: true
        },
        cardType: {
            type: String,
            default: 'follow',
            required: true
        }
    },
    computed: {
        ...mapState({
            routeName: (state) => state.routeName,
        })
    },
    methods: {
        onPageToHomepage(id) {
            if (this.routeName == 'homepage') {
                if (this.$route.params.id == id) {
                    this.$emit('reload')
                } else {
                    this.$emit('homepageStepPage', { to: 'homepage', id: id })
                }
            } else {
                this.$router.push({
                    name: "homepage",
                    params: { id: id, enterType: 'click' },
                    query: { tab: 0 }
                });
            }
        }
    }
}
</script>

<style>
.c-user-cards .account{padding:20px 12px; background: #fff; border-radius: 4px; margin-bottom: 12px; display: flex; justify-content: space-between; align-content: center;}
.c-user-cards .account .left{width:80px; height: 80px;}
.c-user-cards .account .right{width:6px; display: flex; align-items: center;}
.c-user-cards .account .avatar{width:80px; height: 80px;border-radius: 50%; overflow: hidden; background: #ededed;}
.c-user-cards .account .vip{position: absolute; right: 8px; bottom: 0px;}
.c-user-cards .account .center{flex: 1; margin-left: 24px;}
.c-user-cards .account .center h4{font-size: 16px; color: #2E2E30;width:200px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap; font-weight: normal;}
.c-user-cards .account .center .tags_s{display: flex; justify-content: flex-start; align-items: center;margin-top: 2px;}
.c-user-cards .account .center .tag_s{margin-right: 10px;}
.c-user-cards .account .center .info{font-size: 12px; color: #B3B3B3; margin-top: 16px;}
.c-user-cards .account .center .info p{display: inline-block;}
.c-user-cards .account .center .info i{font-style: normal; color: #2E2E30; margin-left: 2px;}
.c-user-cards .account .center .info em{margin: 0 15px; font-style: normal;}
</style>